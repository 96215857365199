import React, { useMemo } from "react";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";

const DisbursementHistory = () => {
    const columns = useMemo(
        () => [
            {
                Header: "Merchant's Account",
                accessor: "senders_account_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "transaction_amount",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Payment Method",
                accessor: "payment_type",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Recipient's Account",
                accessor: "recipient_account_no",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "date",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Status",
                accessor: "transaction_status",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Merchant Payments", link: "/" },
        { title: "Disbursement History", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Disbursement History" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={[] || []}
                                isPagination={false}
                                // isGlobalFilter={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DisbursementHistory;
