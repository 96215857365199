import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { fetchStructuredUsers } from "../utilities/fetchUsers";
import convertDate from "../../Orders/utilities/dateFormatter";

const Merchants = () => {
    const [merchants, setMerchants] = useState([]);
    const authToken = useSelector((state) => state.Login.token);

    const fetchMerchants = async (token) => {
        const allUsers = await fetchStructuredUsers(token);

        setMerchants(allUsers.Merchants);
    };

    useEffect(() => {
        fetchMerchants(authToken);
    }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "first_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Route Tag",
                accessor: "username",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Phone No.",
                accessor: "phone_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date Created",
                accessor: "created_at",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let modifiedDate = convertDate(value);
                    return <React.Fragment>{modifiedDate}</React.Fragment>;
                },
            },
            {
                Header: "Status",
                accessor: "is_active",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let isActive;
                    if (value === true) {
                        isActive = "Active";
                    } else {
                        isActive = "Inactive";
                    }
                    return <>{isActive}</>;
                },
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Users", link: "/" },
        { title: "Merchants", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Merchants" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={merchants || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Merchants;
